<script setup lang="ts">
// import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

const { t, locale } = useI18n({ useScope: 'local' })
const localePath = useLocalePath()

const { init, toggle } = useChatwoot()
onMounted(() => init())

const linkPRO = computed(() => {
  if (locale.value === 'fr')
    return 'https://info846165.typeform.com/to/fG1QuhV8'

  return 'https://info846165.typeform.com/to/WglAwc3E'
})

useHead(useSeo({}))
</script>

<template>
  <div class="text-pretty relative text-[#33335A] bg-white">
    <NuxtLayout name="default" :navbar-dark="false">
      <img
        src="/assets/home/hero-nebula.svg"
        alt="blurred circles"
        class="absolute z-[1] inset-x-0 top-nav-bar-height-current opacity-50"
      >

      <svg
        id="square-cluster"
        viewBox="0 0 1712 1169"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="absolute z-[2] mt-[108px] inset-x-0 top-nav-bar-height-current opacity-15"
      >
        <g id="layer-0">
          <rect x="75" y="526" class="red" />
          <rect x="1354" y="710" class="blue" />
          <rect x="145" y="836" class="blue" />
        </g>
        <g id="layer-1">
          <rect x="1473" y="911" class="blue" />
          <rect x="1516" y="656" class="red" />
          <rect x="1039" y="963" class="red" />
          <rect x="1129" y="717" class="blue" />
          <rect x="238" y="597" class="red" />
          <rect x="5" y="329" class="blue" />
          <rect x="131" y="214" class="blue" />
          <rect x="1355" y="459" class="blue" />
          <rect x="1657" y="556" class="blue" />
        </g>
        <g id="layer-2">
          <rect x="401" y="420" class="red" />
          <rect x="250" y="395" class="blue" />
          <rect x="618" y="576" class="blue" />
          <rect x="1615" y="210" class="blue" />
          <rect x="1073" y="524" class="blue" />
          <rect x="1530" y="348" class="red" />
          <rect x="534" y="818" class="blue" />
          <rect x="466" y="960" class="blue" />
        </g>
        <g id="layer-3">
          <rect x="73" y="941" class="blue" />
          <rect x="1616" y="57" class="blue" />
          <rect x="1097" y="22" class="blue" />
          <rect x="554" y="16" class="red" />
          <rect x="97" y="44" class="blue" />
        </g>
        <defs>
          <filter id="blur-0" filterUnits="userSpaceOnUse">
            <feGaussianBlur stdDeviation="4" />
          </filter>
          <filter id="blur-1" filterUnits="userSpaceOnUse">
            <feGaussianBlur stdDeviation="6" />
          </filter>
          <filter id="blur-2" filterUnits="userSpaceOnUse">
            <feGaussianBlur stdDeviation="8" />
          </filter>
          <filter id="blur-3" filterUnits="userSpaceOnUse">
            <feGaussianBlur stdDeviation="8" />
          </filter>
        </defs>
      </svg>

      <div class="px-4 pt-14 md:pt-28 relative z-10">
        <header id="hero" class="flex flex-col items-center text-center">
          <h1 class="font-display text-4xl leading-[130%] xs:text-5xl md:text-7xl xs:leading-[130%] md:leading-[115%] max-w-6xl tracking-[-0.01em]">
            <i18n-t keypath="hero.title.content">
              <template #highlight>
                <mark class="red-blur">{{ t('hero.title.highlight') }}</mark>
              </template>
              <template #br>
                <br>
              </template>
            </i18n-t>
          </h1>

          <div class="w-full flex items-center h-[422px] -mb-16 -mt-12 lg:mt-12 lg:mb-5">
            <div class="absolute inset-x-0">
              <NuxtImg
                :src="`/assets/home/hero-img-${locale === 'fr' ? 'fr' : 'en'}.webp`"
                alt="Collections d'outils"
                width="1905"
                height="422"
                class="w-auto h-[250px] sm:h-[320px] lg:h-[422px] object-cover object-center"
              />
            </div>
          </div>

          <p class="max-w-3xl font-display text-balance text-xl leading-[175%] mt-6 mb-10">
            {{ t('hero.description') }}
          </p>

          <button
            type="button"
            class="border-cta flex items-center gap-2 py-4 px-14 rounded-full font-display font-semibold"
            @click="toggle('open')"
          >
            <i class="i-appguide-chat-two-bubbles size-5" />
            {{ t('cta.chat') }}
          </button>
          <p class="text-lg leading-[175%] mt-4">
            <i18n-t keypath="cta.or">
              <template #contact>
                <NuxtLink :to="`mailto:${t('cta.contact-mailto')}`" class="underline hover:no-underline hover:text-funky-650">
                  {{ t('cta.contact-us') }}
                </NuxtLink>
              </template>
            </i18n-t>
          </p>
        </header>

        <section id="solutions" class="mt-48 grid grid-cols-1 gap-x-6 gap-y-32 lg:gap-x-10 lg:gap-y-60 lg:grid-cols-2 max-w-[1424px] mx-auto">
          <article class="flex flex-col-reverse gap-y-12 lg:contents max-lg:text-center">
            <div class="text-lg leading-[175%]">
              <p class="text-funky-600 font-display font-semibold mb-2">
                {{ t('solutions.innovation-managers.eyebrow') }}
              </p>
              <h2 class="font-display text-5xl leading-[130%] mb-6 text-balance">
                {{ t('solutions.innovation-managers.title') }}
              </h2>
              <p class="mb-2">
                {{ t('solutions.innovation-managers.description[0]') }}
              </p>
              <p class="mb-6">
                <strong class="font-semibold">{{ t('solutions.innovation-managers.description[1]') }}</strong>
              </p>
              <p class="mb-3">
                {{ t('solutions.innovation-managers.pre-cta') }}
              </p>
              <button type="button" class="flex items-center gap-3 px-6 py-2 rounded-full bg-funky-600/10 text-funky-650 hover:text-white hover:bg-funky-650 max-lg:mx-auto">
                <i class="i-appguide-chat-two-bubbles size-5" />
                <span>
                  <i18n-t keypath="solutions.innovation-managers.cta.content">
                    <template #strong>
                      <strong class="font-semibold">{{ t('solutions.innovation-managers.cta.strong') }}</strong>
                    </template>
                  </i18n-t>
                </span>
              </button>
            </div>

            <div class="w-full lg:w-[calc(100%-80px)] lg:ml-auto">
              <NuxtImg
                :src="`/assets/home/solution-1_${locale === 'fr' ? 'fr' : 'en'}.webp`"
                alt="outils de veille et de sélection d'outils d'autosoins"
                width="651"
                height="468"
                class="xl:ml-6 max-lg:mx-auto md:translate-x-[51px] lg:translate-x-0"
              />
            </div>
          </article>

          <article class="flex flex-col gap-y-12 lg:contents max-lg:text-center">
            <div class="w-full lg:w-[calc(100%-80px)] mr-auto">
              <NuxtImg
                :src="`/assets/home/solution-2_${locale === 'fr' ? 'fr' : 'en'}.webp`"
                alt="Microsite de référence d'outils d'autosoins"
                width="600"
                height="501"
                class="lg:-mt-14 max-lg:mx-auto"
              />
            </div>

            <div class="text-lg leading-[175%]">
              <p class="text-funky-600 font-display font-semibold mb-2">
                {{ t('solutions.your-patient.eyebrow') }}
              </p>
              <h2 class="font-display text-5xl leading-[130%] mb-6 text-balance">
                {{ t('solutions.your-patient.title') }}
              </h2>
              <p class="mb-2 text-balance">
                {{ t('solutions.your-patient.description[0]') }}
                <strong class="font-semibold">
                  {{ t('solutions.your-patient.description[1]') }}
                </strong>
              </p>

              <p class="mb-3">
                {{ t('solutions.your-patient.pre-cta') }}
              </p>

              <div class="flex gap-2 flex-wrap max-lg:justify-center">
                <NuxtLink :to="localePath('resources-collections-quit-smoking')" target="_blank" class="flex items-center gap-3 px-6 py-2 rounded-full font-semibold bg-funky-600/10 text-funky-650 hover:text-white hover:bg-funky-650">
                  {{ t('solutions.your-patient.cta[0]') }}
                  <i class="i-appguide-external size-4" />
                </NuxtLink>
                <NuxtLink :to="localePath('resources-collections-medication-adherence')" target="_blank" class="flex items-center gap-3 px-6 py-2 rounded-full font-semibold bg-funky-600/10 text-funky-650 hover:text-white hover:bg-funky-650">
                  {{ t('solutions.your-patient.cta[1]') }}
                  <i class="i-appguide-external size-4" />
                </NuxtLink>
              </div>
            </div>
          </article>

          <article class="flex flex-col-reverse gap-y-12 lg:contents max-lg:text-center">
            <div class="text-lg leading-[175%] flex flex-col items-center lg:items-start">
              <p class="text-funky-600 font-display font-semibold mb-2">
                {{ t('solutions.health-pro.eyebrow') }}
              </p>
              <h2 class="font-display text-5xl leading-[130%] mb-6 text-balance">
                {{ t('solutions.health-pro.title') }}
              </h2>
              <p class="mb-6">
                {{ t('solutions.health-pro.description') }}
              </p>

              <img
                src="/logo/appguide-pro.svg"
                alt="AppGuide Pro"
                width="168"
                height="24"
                class="mb-2"
              >
              <p class="mb-3">
                <strong class="font-semibold">{{ t('solutions.health-pro.pre-cta') }}</strong>
              </p>
              <NuxtLink
                :to="linkPRO"
                target="_blank"
                class="flex gap-x-1 px-6 py-2 h-12 rounded-full bg-funky-600/10 text-funky-650 hover:text-white hover:bg-funky-650"
              >
                <i18n-t keypath="solutions.health-pro.cta.content">
                  <template #strong>
                    <strong class="font-semibold">{{ t('solutions.health-pro.cta.strong') }}</strong>
                  </template>
                </i18n-t>
              </NuxtLink>
            </div>

            <div class="w-full lg:w-[calc(100%-80px)] ml-auto">
              <NuxtImg
                :src="`/assets/home/solution-3_${locale === 'fr' ? 'fr' : 'en'}.webp`"
                alt="App share"
                width="600"
                height="518"
                class="lg:-mt-14 lg:ml-auto max-lg:mx-auto"
              />
            </div>
          </article>

          <div class="lg:col-span-2 flex items-center justify-center text-center flex-col lg:-mt-20">
            <button
              type="button"
              class="border-cta flex items-center gap-2 py-4 px-14 rounded-full font-display font-semibold"
              @click="toggle('open')"
            >
              <i class="i-appguide-chat-two-bubbles size-5" />
              {{ t('cta.chat') }}
            </button>
            <p class="text-lg leading-[175%] mt-4">
              <i18n-t keypath="cta.or">
                <template #contact>
                  <NuxtLink :to="`mailto:${t('cta.contact-mailto')}`" class="underline hover:no-underline hover:text-funky-650">
                    {{ t('cta.contact-us') }}
                  </NuxtLink>
                </template>
              </i18n-t>
            </p>
          </div>
        </section>

        <section id="testimonials" class="max-w-7xl mx-auto grid lg:grid-cols-2 gap-12 xl:gap-x-24 mt-44 mb-32">
          <div class="bg-white shadow-card rounded-3xl p-12 md:p-16 lg:-translate-y-8">
            <h2 class="text-funky-600 text-lg font-display font-semibold mb-2">
              {{ t('testimonials.pier-luc.title') }}
            </h2>
            <blockquote>
              <p class="text-2xl text-violet-800 leading-[150%]" :class="locale">
                <i18n-t keypath="testimonials.pier-luc.quote">
                  <template #highlight>
                    <mark>{{ t('testimonials.pier-luc.quote-highlight') }}</mark>
                  </template>
                </i18n-t>
              </p>
              <footer class="leading-[175%] mt-6">
                <NuxtImg
                  src="/assets/photo/pierre-luc-dechantal.jpg"
                  width="112"
                  height="112"
                  alt="Pier-Luc de Chantal"
                  class="size-14 rounded-full float-left mr-6"
                />
                <i18n-t keypath="testimonials.pier-luc.author.content">
                  <template #name>
                    <strong class="font-semibold">{{ t('testimonials.pier-luc.author.name') }}</strong>
                  </template>
                </i18n-t><br>
                <cite class="not-italic">
                  <NuxtLink to="https://www.frontiersin.org/journals/digital-health/articles/10.3389/fdgth.2022.765993/full" target="_blank" class="underline hover:no-underline hover:text-funky-650">
                    Frontiers Digital Health (2022)
                  </NuxtLink>
                </cite>
              </footer>
            </blockquote>
          </div>
          <div class="bg-white shadow-card rounded-3xl p-12 md:p-16 lg:translate-y-8">
            <h2 class="text-funky-600 text-lg font-display font-semibold mb-2">
              {{ t('testimonials.martin-payer.title') }}
            </h2>
            <blockquote>
              <p class="text-2xl text-violet-800 leading-[150%]" :class="locale">
                <i18n-t keypath="testimonials.martin-payer.quote">
                  <template #highlight>
                    <mark>{{ t('testimonials.martin-payer.quote-highlight') }}</mark>
                  </template>
                </i18n-t>
              </p>
              <footer class="leading-[175%] mt-6 flex items-center">
                <NuxtImg
                  src="/assets/photo/martin-payer.jpg"
                  width="112"
                  height="112"
                  alt="Martin Payer, pharmacien"
                  class="size-14 rounded-full mr-6"
                />
                <strong class="font-semibold">
                  {{ t('testimonials.martin-payer.author') }}
                </strong>
              </footer>
            </blockquote>
          </div>
        </section>

        <img aria-hidden="true" src="/assets/home/end-nebula.svg" alt="background nebula" class="absolute inset-x-0 -z-10 bottom-32 w-full">

        <section id="organisations" class="bg-white shadow-card rounded-3xl mx-auto max-w-7xl py-20 text-center -mb-40 md:-mb-32 max-md:-mt-20">
          <h2 class="font-display text-lg font-semibold text-funky-600 mb-10 text-balance">
            {{ t('organisations') }}
          </h2>

          <div class="flex items-center justify-center gap-12 flex-wrap">
            <NuxtImg
              width="175"
              height="112"
              src="/assets/home/img_trust-us-ciusss-est-montreal.jpg"
              alt="CIUSSS de l'Est-de-l'Île-de-Montréal"
            />
            <img
              width="175"
              height="112"
              src="/assets/home/img_trust-us-universite-laval.svg"
              alt="Université Laval"
            >
            <img
              width="175"
              height="112"
              src="/assets/home/img_trust-us-alberta-innovates.svg"
              alt="Alberta Innovates"
            >
            <img
              width="175"
              height="112"
              src="/assets/home/img_trust-us-ismees.svg"
              alt="Initiative sur la santé mentale étudiante en enseignement supérieur"
            >
            <img
              width="175"
              height="112"
              src="/assets/home/img_trust-us-heart-n-stroke.svg"
              alt="Heart and Stroke Foundation of Canada"
            >
          </div>
        </section>
      </div>

      <div class="px-4 pt-72 pb-52 bg-violet-800 text-white">
        <section id="data" class="max-w-[1424px] mx-auto">
          <h2 class="text-5xl md:text-6xl leading-[130%] max-w-4xl mx-auto text-center">
            <mark class="blue-blur relative text-7xl md:text-8xl inline-block mr-[-0.75ch]">
              750
              <span class="red-blur absolute right-[0.75ch] inline-block">+</span>
            </mark>

            <i18n-t keypath="data.title.content">
              <template #highlight>
                <mark class="blue-blur">{{ t('data.title.highlight') }}</mark>
              </template>
            </i18n-t>
          </h2>

          <div class="grid lg:grid-cols-2 mt-48 gap-y-12 gap-x-10 items-start">
            <div>
              <h3 class="font-display text-5xl mb-16 leading-[130%]">
                {{ t('data.sub-title') }}
              </h3>

              <h4 class="text-2xl font-display mb-6">
                {{ t('data.examples.title') }}
              </h4>
              <ul class="pl-6 list-disc space-y-1 text-lg leading-[175%]">
                <li>{{ t('data.examples.list[0]') }}</li>
                <li>{{ t('data.examples.list[1]') }}</li>
                <li>{{ t('data.examples.list[2]') }}</li>
                <li>{{ t('data.examples.list[3]') }}</li>
                <li>{{ t('data.examples.list[4]') }}</li>
              </ul>

              <h4 class="text-2xl font-display mb-6 mt-10">
                {{ t('data.populars.title') }}
              </h4>
              <ul class="pl-6 list-disc space-y-1 text-lg leading-[175%] grid sm:grid-rows-4 sm:grid-flow-col-dense">
                <li>{{ t('data.populars.list[0]') }}</li>
                <li>{{ t('data.populars.list[1]') }}</li>
                <li>{{ t('data.populars.list[2]') }}</li>
                <li>{{ t('data.populars.list[3]') }}</li>
                <li>{{ t('data.populars.list[4]') }}</li>
                <li>{{ t('data.populars.list[5]') }}</li>
                <li>{{ t('data.populars.list[6]') }}</li>
                <li>{{ t('data.populars.list[7]') }}</li>
              </ul>
            </div>
            <div class="w-full lg:w-[calc(100%-80px)] ml-auto bg-white/5 p-8 sm:p-16 rounded-2xl">
              <h4 class="text-lg font-display font-semibold leading-[175%]">
                {{ t('data.stats.title') }}
              </h4>
              <div class="mt-10 space-y-6">
                <p class="flex items-baseline text-lg leading-[175%]">
                  <strong class="block flex-none blue-blur font-display text-2xl w-16">50%</strong>
                  {{ t('data.stats.list[0]') }}
                </p>
                <p class="flex items-baseline text-lg leading-[175%]">
                  <strong class="block flex-none blue-blur font-display text-2xl w-16">4%</strong>
                  {{ t('data.stats.list[1]') }}
                </p>
                <p class="flex items-baseline text-lg leading-[175%]">
                  <strong class="block flex-none blue-blur font-display text-2xl w-16">20%</strong>
                  {{ t('data.stats.list[2]') }}
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <!-- <div class="px-6 pt-44 pb-52">
        <h2 class="text-center mb-18 text-lg font-semibold font-display text-funky-600">
          FAQ
        </h2>

        <div class="max-w-7xl mx-auto space-y-1">
          <Disclosure
            v-for="(faq, index) in [
              { question: 'Qu’est-ce que AppGuide?' },
              { question: 'Est-ce qu’AppGuide développe une appli santé?' },
              { question: 'Qui peuvent se servir d’AppGuide?' },
              { question: 'Comment AppGuide évalue les applications, et que sont les « Rapports de confiance »?' },
              { question: 'Comment AppGuide supporte les développeurs d’outils?' },
              { question: '[...]' },
              { question: '[...]' },
            ]"
            :key="index"
            v-slot="{ open }"
            as="div"
            class="bg-gray-100 rounded-lg"
          >
            <DisclosureButton
              class="flex text-2xl leading-[150%] items-center justify-between p-6 w-full hover:bg-gray-400/10 rounded-lg"
              :class="{
                'bg-gray-400/10': open,
              }"
            >
              {{ faq.question }}
              <i
                class="i-appguide-plus size-6 transition-transform"
                :class="{
                  '-rotate-45': open,
                }"
              />
            </DisclosureButton>
            <DisclosurePanel class="p-6 text-lg leading-[175%]">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos voluptates officiis tenetur laborum nemo explicabo. Earum minima ut doloremque voluptatum corrupti sit dolor maxime culpa, maiores est dolore a rem?
            </DisclosurePanel>
          </Disclosure>
        </div>
      </div> -->

      <div class="-mb-36 grid lg:grid-cols-2 text-white">
        <div :style="{ backgroundImage: 'url(/assets/home/bottom-left-bg.jpg)' }" class="p-8 sm:p-12 md:p-24 flex flex-col items-start bg-cover font-display">
          <h2 class="text-lg font-semibold mb-2">
            {{ t('footer-cta.learn-more.title') }}
          </h2>
          <p class="text-4xl md:text-5xl leading-[130%] md:leading-[130%] mb-10" :class="{ capitalize: locale !== 'fr' }">
            {{ t('footer-cta.learn-more.description') }}
          </p>

          <div class="flex flex-wrap items-center gap-6 mt-auto">
            <button
              type="button"
              class="bg-transparent font-semibold text-xl py-4 px-10 rounded-full border-2 border-white hover:bg-white hover:text-red-700"
              @click="toggle('open')"
            >
              {{ t('cta.chat') }}
            </button>
            <p class="text-lg leading-[175%] font-body">
              <i18n-t keypath="cta.or">
                <template #contact>
                  <NuxtLink :to="`mailto:${t('cta.contact-mailto')}`" class="underline hover:no-underline">
                    {{ t('cta.contact-us') }}
                  </NuxtLink>
                </template>
              </i18n-t>
            </p>
          </div>
        </div>
        <div :style="{ backgroundImage: 'url(/assets/home/bottom-right-bg.jpg)' }" class="p-8 sm:p-12 md:p-24 flex flex-col items-start bg-cover font-display">
          <h2 class="text-lg font-semibold mb-2">
            {{ t('footer-cta.appguide-pro.title') }}
          </h2>
          <p class="text-4xl md:text-5xl leading-[130%] md:leading-[130%] mb-10" :class="{ capitalize: locale !== 'fr' }">
            {{ t('footer-cta.appguide-pro.description') }}
          </p>

          <NuxtLink
            :to="linkPRO"
            target="_blank"
            class="mt-auto bg-transparent font-semibold text-xl py-4 px-10 rounded-full text-white border-2 border-white hover:bg-white hover:text-funky-650"
          >
            {{ t('footer-cta.appguide-pro.cta') }}
          </NuxtLink>
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>

<style scoped lang="scss">
.bg-hero {
  @apply absolute z-0 inset-0 bg-white overflow-hidden;

  .mask-1 {
    @apply absolute inset-0;

    z-index: 3;
    background: linear-gradient(360deg, rgba(34, 32, 66, 0) 0%, rgba(34, 32, 66, 0.6) 40.62%, #222042 56.77%);
    transform: rotate(180deg);
  }

  .mask-2 {
    @apply absolute inset-0;

    z-index: 2;
    background: radial-gradient(71.6% 99.45% at 97.5% 124.01%, rgba(27, 92, 189, 0.4) 0%, rgba(29, 86, 176, 0.46) 5%, rgba(37, 60, 117, 0.76) 32%, rgba(42, 45, 81, 0.94) 62%, #2C2846 100%);
    opacity: 0.46;
    transform: rotate(180deg);
  }

  .mask-3 {
    @apply absolute inset-0;

    z-index: 1;
    background: linear-gradient(90deg, #59478E -13.29%, #57468B -11.29%, #483E74 9.71%, #3D3863 31.71%, #373459 55.71%, #353356 86.71%);
  }
}

.bg-section-blue {
  background: linear-gradient(180deg, #E3EDFB 0%, rgba(#E3EDFB, 0%) 100%);
}

#square-cluster {
  #layer-0 {
    --size: 70px;
    --filter: url(#blur-0);
    --opacity: 0.6;
  }
  #layer-1 {
    --size: 56px;
    --filter: url(#blur-1);
    --opacity: 0.5;
  }
  #layer-2 {
    --size: 32px;
    --filter: url(#blur-2);
    --opacity: 0.4;
  }
  #layer-3 {
    --size: 24px;
    --filter: url(#blur-3);
    --opacity: 0.4;
  }

  rect {
    width: var(--size);
    height: var(--size);
    filter: var(--filter);
    opacity: var(--opacity);
  }

  .blue { fill: #7C3AED; fill: oklch(53.92% 0.2983 294.93) }
  .red { fill: #F472B6; fill: oklch(73.68% 0.207 349.76) }

  @keyframes float-1 {
    0% { transform: translateY(0) }
    50% { transform: translateY(2%) }
    100% { transform: translateY(0) }
  }
  @keyframes float-2 {
    0% { transform: translateX(0) translateY(0) }
    50% { transform: translateX(2%) translateY(1%) }
    100% { transform: translateX(0) translateY(0) }
  }
}

mark.blue-blur {
  @apply bg-transparent text-current;

  background:
    linear-gradient(rgba(0, 0, 0, 0.00) 15%, rgba(0, 0, 0, 0.20) 75%),
    linear-gradient(178deg, #00E3FF 42%, #3785EB 77%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  text-shadow: rgba(#01E3FF, 0.3) 0 0 24px;

  .red-blur {
    text-shadow: none;
  }
}
.blue-blur {
  background:
    linear-gradient(rgba(0, 0, 0, 0.00) 15%, rgba(0, 0, 0, 0.20) 75%),
    linear-gradient(178deg, #00E3FF 42%, #3785EB 77%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

mark.red-blur,
mark .red-blur {
  @apply relative bg-transparent text-current;

  background: linear-gradient(178deg, #E02267 20%, #7C3AED 90%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.border-cta {
  @apply relative bg-transparent ring-gray-400/10 text-gray-700;

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 9999px;
    padding: 2px;
    background: linear-gradient(151deg, #E02267 0%, #7C3AED 80%);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
  }

  &:disabled {
    @apply bg-transparent border-2 border-gray-200;

    &:before {
      opacity: 0;
    }
  }

  &:hover {
    color: white;
    background: linear-gradient(151deg, #E02267 0%, #7C3AED 80%);
  }
}

blockquote {
  .en {
    &::before { content: '“' }
    &::after { content: '”' }
  }
  .fr {
    &::before { content: '«' }
    &::after { content: '»' }
  }

  mark {
    @apply bg-transparent text-current underline decoration-red-500/20 decoration-4;

    -webkit-box-decoration-break: clone;
    -o-box-decoration-break: clone;
    box-decoration-break: clone;
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "cta": {
      "chat": "Chat With An Expert",
      "or": "or {contact}",
      "contact-us": "contact us",
      "contact-mailto": "support{'@'}therappx.com?subject=Talk%20to%20an%20expert%20-%20AppGuide"
    },
    "hero": {
      "title": {
        "content": "Strengthen Your Healthcare Offering With Trusted {br} {highlight}",
        "highlight": "Self-Care Tools"
      },
      "description": "Save time and money sorting through 325,000 applications, and deploy your selected tool collections in days rather than months."
    },
    "solutions": {
      "innovation-managers": {
        "eyebrow": "For Innovation Managers",
        "title": "Create Your Own Tool Collections",
        "description": [
          "Accelerate your monitoring and selection of digital health tools with reliable and up-to-date evaluation data.",
          "90% of tasks have already been completed."
        ],
        "pre-cta": "To find out more or order a tool watch :",
        "cta": {
          "content": "{strong} with an expert",
          "strong": "Chat"
        }
      },
      "your-patient": {
        "eyebrow": "For Your Patient",
        "title": "Distribute Trusted Tools Efficiently",
        "description": [
          "Create a microsite that refers your patients to quality-assured tools tailored to their needs.",
          "Deploy a collection in less than 10 days, at 10% of normal costs."
        ],
        "pre-cta": "Microsite realizations :",
        "cta": [
          "Smoking cessation",
          "Medication adherence"
        ]
      },
      "health-pro": {
        "eyebrow": "For Health Professionals",
        "title": "Refer Your Patient To The Best Tool",
        "description": "Access the collections provided by your organization straight from your professional software.",
        "pre-cta": "Discover the 8 collections designed by our clinicians.",
        "cta": {
          "content": "Request your access to {strong}",
          "strong": "AppGuide PRO"
        }
      }
    },
    "testimonials": {
      "pier-luc": {
        "title": "Go Beyond Popularity Ratings",
        "quote": "The 5-star reviews found in app stores are often uninformative. Our study shows the value of a library of expert-reviewed apps {highlight}.",
        "quote-highlight": "to help you find more specific health apps",
        "author": {
          "name": "Pier-Luc de Chantal",
          "content": "{name} & al."
        },
        "source": "Frontiers Digital Health (2022)"
      },
      "martin-payer": {
        "title": "Time And Energy Saving",
        "quote": "{highlight}. It saves me the trouble of helping the patient download the tool and refers to the app for support. I save a lot of time and energy.",
        "quote-highlight": "It simplifies my experience of recommending apps as part of my patients’ diabetes care",
        "author": "Martin Payer, Pharmacist"
      }
    },
    "organisations": "Healthcare Organizations That Trust AppGuide",
    "data": {
      "title": {
        "content": "Inventoried Studies Demonstrate The Potential Of These Tools {highlight}",
        "highlight": "To Improve Care"
      },
      "sub-title": "Digital Health Tools Offer Accessible Solutions",
      "examples": {
        "title": "Examples of documented benefits ",
        "list": [
          "Reducing appointments and re-hospitalization",
          "Prevention and health promotion",
          "Adherence to treatment plan",
          "Time savings in chronic disease management",
          "Etc."
        ]
      },
      "populars": {
        "title": "Most popular themes",
        "list": [
          "Mental health",
          "Addiction",
          "Home care",
          "Diabetes",
          "Cardiology",
          "Chronic pain",
          "Employee assistance programs (EAP)",
          "Etc."
        ]
      },
      "stats": {
        "title": "Digital health tools statistics",
        "list": [
          "of citizens tried a health app downloaded from traditional app marketplaces in 2021.",
          "of these apps offer functionalities relevant for self-care.",
          "of them maintain a sufficient trust score to be recommended."
        ]
      }
    },
    "footer-cta": {
      "learn-more": {
        "title": "Learn more",
        "description": "Talk to an expert about our products and services"
      },
      "appguide-pro": {
        "title": "Get started with AppGuide PRO",
        "description": "Discover now our free collections of self-care tools",
        "cta": "Request Your Access"
      }
    }
  },
  "fr": {
    "cta": {
      "chat": "Clavardez avec un expert",
      "or": "ou {contact}",
      "contact-us": "contactez-nous",
      "contact-mailto": "support{'@'}therappx.com?subject=Parler%20%C3%A0%20un%20expert%20%E2%80%A2%20AppGuide"
    },
    "hero": {
      "title": {
        "content": "Renforcez votre offre de santé avec des {highlight} de confiance",
        "highlight": "outils d’autosoins"
      },
      "description": "Sauvez temps et argent à faire le tri parmi 325 000 applications et déployez vos collections d’outils sélectionnés en quelques jours plutôt qu’en mois."
    },
    "solutions": {
      "innovation-managers": {
        "eyebrow": "Pour vos gestionnaires d'innovation",
        "title": "Créez vos propres collections d’outils",
        "description": [
          "Accélérez vos veilles et la sélection d’outils numériques en santé avec des données d’évaluation fiables et constament mises à jour.",
          "90% des tâches ont déjà été accomplies."
        ],
        "pre-cta": "Pour commander une veille d’outils ou en savoir plus :",
        "cta": {
          "content": "{strong} avec un expert",
          "strong": "Clavardez"
        }
      },
      "your-patient": {
        "eyebrow": "Pour vos patients",
        "title": "Distribuez efficacement des outils de confiance",
        "description": [
          "Créez un microsite qui réfère vos patients vers des outils adaptés à leurs besoins et qui ont reçu une assurance qualité.",
          "Déployez une collection en moins de 10 jours, à 10% des coûts usuels."
        ],
        "pre-cta": "Réalisations de microsites :",
        "cta": [
          "Arrêt tabagique",
          "Adhésion médicamenteuse"
        ]
      },
      "health-pro": {
        "eyebrow": "Pour les professionnels de la santé",
        "title": "Référez votre patient vers le meilleur outil ",
        "description": "Accédez aux collections fournies par votre organisation à même votre logiciel professionnel.",
        "pre-cta": "Découvrez les 8 collections conçu par nos cliniciens.",
        "cta": {
          "content": "Demandez votre {strong}",
          "strong": "accès à AppGuide PRO"
        }
      }
    },
    "testimonials": {
      "pier-luc": {
        "title": "Au-delà des cotes de popularité",
        "quote": "Les évaluations sur 5 étoiles trouvées dans les magasins d’applis sont souvent peu informatives. Notre étude montre la valeur d’une bibliothèque d’applications évaluées par des experts pour {highlight}.",
        "quote-highlight": "vous aider à trouver des applications de santé plus spécifiques",
        "author": {
          "name": "Pier-Luc de Chantal",
          "content": "{name} et al."
        },
        "source": "Frontiers Digital Health (2022)"
      },
      "martin-payer": {
        "title": "Économie de temps et d’énergie",
        "quote": "{highlight}. Ça m’évite d’avoir à aider le patient à télécharger l’outil et réfère à l’application pour obtenir de l’aide. J’économise beaucoup de temps et d’énergie.",
        "quote-highlight": "Ça simplifie mon expérience de recommandation d’apps dans le cadre du suivi du diabète de mes patients",
        "author": "Martin Payer, pharmacien"
      }
    },
    "organisations": "Des organisations de santé qui font confiance à AppGuide",
    "data": {
      "title": {
        "content": "études recensées démontrent le potentiel de ces outils {highlight}",
        "highlight": "à améliorer les soins"
      },
      "sub-title": "Les outils numériques en santé offrent des solutions accessibles",
      "examples": {
        "title": "Exemples de bénéfices documentés ",
        "list": [
          "Réduction des rendez-vous ou de réhospitalisations",
          "Prévention et promotion de la santé",
          "Adhésion au plan de traitement",
          "Gains de temps dans le suivi d’une maladie chronique",
          "Etc."
        ]
      },
      "populars": {
        "title": "Thématiques populaires",
        "list": [
          "Santé mentale",
          "Dépendance",
          "Soins à domicile",
          "Diabète",
          "Cardiologie",
          "Douleur chronique",
          "Programmes d’aide aux employés (PAE)",
          "Etc."
        ]
      },
      "stats": {
        "title": "Statistiques sur les outils numériques en santé",
        "list": [
          "des citoyens ont fait l’essai d’une application santé téléchargée sur les marchés tradtionnels d’applications, en 2021.",
          "de ces applications, seulement, offrent des fonctionnalités pertinentes pour les autosoins.",
          "d’entres-elles maintiennent un score de confiance suffisant pour être recommandé."
        ]
      }
    },
    "footer-cta": {
      "learn-more": {
        "title": "En savoir plus",
        "description": "Parlez avec un expert à propos de nos produits et services"
      },
      "appguide-pro": {
        "title": "Démarrer avec AppGuide PRO",
        "description": "Découvrez maintenant nos collections gratuites d’outils d’autosoins",
        "cta": "Demander votre accès"
      }
    }
  }
}
</i18n>
